import React from 'react';
import { buildClassName } from 'utils/build-class-name';
import ArrowIcon from 'components/icon/arrow-icon';
import styles from './style.module.scss';
import { testIds } from 'constants/test-constants';

interface Props {
  isListingCardCarousel?: boolean;
  isLightbox: boolean | undefined;
  slideTo: (slide: 'next' | 'prev' | number) => void;
  className?: string;
}

export default function CarouselArrows({ isListingCardCarousel, slideTo, className, isLightbox }: Props) {
  const arrowClass = (type: 'next' | 'prev') => buildClassName(styles.component, styles[type], isListingCardCarousel && styles.card, isLightbox && styles.lightbox, className);

  return <>
    <span onClick={() => slideTo('prev')} className={arrowClass('prev')} ><ArrowIcon /></span>
    <span onClick={() => slideTo('next')} className={arrowClass('next')} data-testid={testIds.carouselNext}><ArrowIcon /></span>
  </>;
}
